import React from 'react';

const AdminSettings = () => {
  return (
    <div className="container">
      <h1>Admin Settings</h1>
      <div className="section">
        <h2>Ban an Address</h2>
        <form method="post" action="/admin/ban">
          <input type="text" name="faddress" placeholder="Enter address to ban" required />
          <input type="email" name="femail" placeholder="Enter email address" />
          <input type="text" name="freason" placeholder="Enter reason for ban" required />
          <button type="submit">Ban</button>
        </form>
      </div>
      <div className="section">
        <form method="post" action="/admin/sendglobalmessage">
          <h2>Send Global Message</h2>
          <input type="text" name="title" placeholder="Titel" required />
          <textarea name="content" placeholder="Enter message"></textarea>
          <select name="type" required>
            <option value="info">Info</option>
            <option value="warning">Warnung</option>
            <option value="event">Event</option>
          </select>
          <button type="submit">Senden</button>
        </form>
      </div>
      <div className="section">
        <form method="post" action="/admin/sendmail">
          <h2>Send Mail</h2>
          <input type="text" name="subject" placeholder="Titel" required />
          <textarea name="content" placeholder="Enter message"></textarea>
          <input type="email" name="to_email" placeholder="Receiver Email" required />
          <select name="type" required>
            <option value="support@biedenkopf.org">support@biedenkopf.org</option>
            <option value="chronus.lock@biedenkopf.org">chronus.lock@biedenkopf.org</option>
            <option value="noreply@biedenkopf.org">noreply@biedenkopf.org</option>
          </select>
          <button type="submit">Senden</button>
        </form>
      </div>
    </div>
  );
};

export default AdminSettings;
