// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not_found {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center vertically */
    align-items: center;     /* Center horizontally */       /* Ensure the container takes up full viewport height */
}

.not_found_link {
    color: #fff;
}

.planet {
    font-size: 100px;
    animation: rotate 10s infinite linear;
    text-align: center;
    max-width: 600px; /* Optionale Breitenbegrenzung für bessere Lesbarkeit */
    padding: 20px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/404/404.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB,EAAE,2BAA2B;IACnD,uBAAuB,EAAE,sBAAsB;IAC/C,mBAAmB,MAAM,wBAAwB,QAAQ,uDAAuD;AACpH;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB,EAAE,uDAAuD;IACzE,aAAa;AACjB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".not_found {\n    display: flex;\n    flex-direction: column; /* Align items vertically */\n    justify-content: center; /* Center vertically */\n    align-items: center;     /* Center horizontally */       /* Ensure the container takes up full viewport height */\n}\n\n.not_found_link {\n    color: #fff;\n}\n\n.planet {\n    font-size: 100px;\n    animation: rotate 10s infinite linear;\n    text-align: center;\n    max-width: 600px; /* Optionale Breitenbegrenzung für bessere Lesbarkeit */\n    padding: 20px;\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
