import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Falls du globale Styles hast
import App from './App'; // Importiere die Haupt-App-Komponente

const root = ReactDOM.createRoot(document.getElementById('root')); // Binde an das root-Div im HTML
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
