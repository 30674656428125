const logout = () => {
  // Alle Cookies durchgehen
  const cookies = document.cookie.split(';');
  
  // Durch alle Cookies iterieren und löschen, außer 'cookies-accepted'
  cookies.forEach(cookie => {
    const cookieName = cookie.split('=')[0].trim();
    
    // Überprüfen, ob der Cookie nicht 'cookies-accepted' ist
    if (cookieName !== 'cookies-accepted') {
      // Setzen des Cookies auf ein vergangenes Datum, um es zu löschen
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }
  });

  // Optional: Benutzer abmelden (z.B. durch Redirect oder Setzen eines States)
  window.location.href = '/'; // Beispiel: Weiterleitung zur Login-Seite
};

export default logout;