import React, { useEffect, useState } from 'react';
import './profile.css';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: 'Gast',
    email: 'Keine E-Mail verfügbar',
    picture: 'https://via.placeholder.com/150',
    isAuthenticated: false,
  });

  // Cookies auslesen und Benutzerinformationen setzen
  useEffect(() => {
    const getCookieValue = (name) => {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      return match ? decodeURIComponent(match[2]) : null;
    };

    const name = getCookieValue('name');
    const email = getCookieValue('email');
    const picture = getCookieValue('picture');

    setUserData({
      name: name || 'Gast',
      email: email || 'Keine E-Mail verfügbar',
      picture: picture || 'https://via.placeholder.com/150',
      isAuthenticated: !!name, // Benutzer ist authentifiziert, wenn ein Name vorhanden ist
    });
  }, []);

  // Logout-Funktion
  const handleLogout = () => {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName !== 'cookies-accepted') {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      }
    });
    setUserData({
      name: 'Gast',
      email: 'Keine E-Mail verfügbar',
      picture: 'https://via.placeholder.com/150',
      isAuthenticated: false,
    });
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Dein Profil</h1>
      </div>
      <div className="profile-content">
        <img src={userData.picture} alt={userData.name} className="profile-picture" />
        <h2>{userData.name}</h2>
        <p>{userData.email}</p>
      </div>
      <div className="profile-actions">
        {userData.isAuthenticated ? (
          <button className="logout-button" onClick={handleLogout}>Abmelden</button>
        ) : (
          <a href="/login" className="login-button">Anmelden</a>
        )}
      </div>
    </div>
  );
};

export default Profile;
