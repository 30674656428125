// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    color: #fff;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profile-header {
    margin-bottom: 20px;
  }
  
  .profile-content {
    margin-bottom: 20px;
  }
  
  .profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
  }
  
  .profile-content h2 {
    font-size: 24px;
    margin: 15px 0;
  }
  
  .profile-content p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .profile-actions .logout-button,
  .profile-actions .login-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #d67316;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .profile-actions .logout-button:hover,
  .profile-actions .login-button:hover {
    background-color: #a55012;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Profile/profile.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,0CAA0C;IAC1C,kBAAkB;IAClB,WAAW;IACX,8BAA8B;IAC9B,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;;IAEE,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,sCAAsC;EACxC;;EAEA;;IAEE,yBAAyB;EAC3B","sourcesContent":[".profile-container {\n    max-width: 600px;\n    margin: 50px auto;\n    padding: 20px;\n    border: 1px solid #d1d1d1;\n    border-radius: 10px;\n    background-color: rgba(255, 255, 255, 0.1);\n    text-align: center;\n    color: #fff;\n    font-family: Arial, sans-serif;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  }\n  \n  .profile-header {\n    margin-bottom: 20px;\n  }\n  \n  .profile-content {\n    margin-bottom: 20px;\n  }\n  \n  .profile-picture {\n    width: 120px;\n    height: 120px;\n    border-radius: 50%;\n    object-fit: cover;\n    border: 3px solid #fff;\n  }\n  \n  .profile-content h2 {\n    font-size: 24px;\n    margin: 15px 0;\n  }\n  \n  .profile-content p {\n    font-size: 18px;\n    margin: 10px 0;\n  }\n  \n  .profile-actions .logout-button,\n  .profile-actions .login-button {\n    padding: 10px 20px;\n    font-size: 16px;\n    color: #fff;\n    background-color: #d67316;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    text-decoration: none;\n    transition: background-color 0.3s ease;\n  }\n  \n  .profile-actions .logout-button:hover,\n  .profile-actions .login-button:hover {\n    background-color: #a55012;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
