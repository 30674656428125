import React, { useEffect } from 'react';

const GoogleAuth = () => {
  useEffect(() => {
    const handleAuthResponse = async () => {
      // Parse the URL to get Google response parameters
      const params = new URLSearchParams(window.location.search);

      const code = params.get('code'); // the authorization code
      const state = params.get('state'); // optional, if you use state

      if (!code) {
        console.error("Authorization code not found in URL.");
        return;
      }

      if (state) {
        console.log("State parameter received from Google:", state);
      }

      // Prepare the data to send to the Rust backend
      const data = {
        code,  // authorization code from Google
        state, // optional: if using state, pass it along
      };

      try {
        // Send the data to the Rust server
        console.log("Fetching data from Rust server...");

        const response = await fetch(`${process.env.REACT_APP_RUST_SERVER_URL}/api/google/callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          window.location.href = "/";
          throw new Error(`Server response was not ok: ${response.statusText}`);
        }

        const result = await response.json();
        console.log("Response from Rust server:", result);

        // If successful, save the tokens to cookies
        if (result.gid) {
          document.cookie = `gid=${result.gid}; path=/; secure; samesite=strict`;
        }
        if (result.email) {
          document.cookie = `email=${result.email}; path=/; secure; samesite=strict`;
        }
        if (result.name) {
          document.cookie = `name=${result.name}; path=/; secure; samesite=strict`;
        }
        if (result.given_name) {
          document.cookie = `given_name=${result.given_name}; path=/; secure; samesite=strict`;
        }
        if (result.family_name) {
          document.cookie = `family_name=${result.family_name}; path=/; secure; samesite=strict`;
        }
        if (result.picture) {
          document.cookie = `picture=${result.picture}; path=/; secure; samesite=strict`;
        }


        // Optionally redirect after successful login
        window.location.href = "/"; // For example

      } catch (error) {
        console.error("Error sending data to Rust server:", error);
      }
    };

    handleAuthResponse();
  }, []);

  return (
    <div>
      <h1>Processing authentication...</h1>
    </div>
  );
};

export default GoogleAuth;
