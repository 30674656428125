import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/home';
import Login from './pages/Login/Login';
import GoogleLogin from './pages/Google/Login';
import GoogleAuth from './pages/Google/Auth';
import Logout from './pages/Logout/logout';
import Register from './pages/Register/Register';
import Profile from './pages/Profile/profile';
import Admin from './pages/Admin/admin';
import NotVerified from './pages/404/404';
import InternalServerError from './pages/404/404';
import NotFoundPage from './pages/404/404';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/google/login' element={<GoogleLogin />} />
        <Route path='/google/auth' element={<GoogleAuth />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/Register' element={<Register />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/Admin/Settings' element={<Admin />} />
        <Route path='/NotVerified' element={<NotVerified />} />
        <Route path='/505' element={<InternalServerError />} />
        <Route path="*" element={<NotFoundPage />} /> {/* 404-Seite */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
