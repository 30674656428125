import React, { useState, useEffect } from 'react';
import AppRouter from './router'; // Importiere den Router

function App() {
  const backgroundImageUrl = `${window.location.origin}${process.env.REACT_APP_BACKGROUND_URL}`;

  // State für Cookie-Zustimmung und Anzeige der Cookie-Meldung
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(true);  // Variable für die Anzeige der Cookie-Meldung

  useEffect(() => {
    // Prüfen, ob bereits zugestimmt wurde (Cookie bereits gesetzt)
    const cookiesExist = document.cookie.includes('cookies-accepted=true');
    setCookiesAccepted(cookiesExist);
    if (cookiesExist) {
      setShowCookieBanner(false);  // Deaktivieren, wenn bereits zugestimmt wurde
    }
  }, []);

  const handleAcceptCookies = () => {
    document.cookie = "cookies-accepted=true; path=/; max-age=" + 60 * 60 * 24 * 365 + "; SameSite=Lax; Secure";
    setCookiesAccepted(true);
    setShowCookieBanner(false);  // Banner nach der Zustimmung ausblenden
  };

  return (
    <div className={!cookiesAccepted ? "block-interaction" : ""}>
      {/* Hintergrund */}
      <div
        className="background-container"
        style={{
          background: `url(${backgroundImageUrl}) no-repeat center top / cover`,
        }}
      ></div>

      {/* Cookie-Banner */}
      {showCookieBanner && !cookiesAccepted && (
        <div className="cookie-overlay">
          <div className="cookie-banner">
            <p>
              Diese Website verwendet Cookies, um Ihre Erfahrung zu verbessern. Bitte akzeptieren Sie Cookies, um fortzufahren.
            </p>
            <button onClick={handleAcceptCookies} className="accept-button">
              Akzeptieren
            </button>
          </div>
        </div>
      )}

      {/* App-Router */}
      <AppRouter />
    </div>
  );
}

export default App;
