import React from 'react';
import './404.css';

const NotFound = () => {
    return (
        <div className='background'>
            <div className="container">
                <div className='not_found'>
                    <div className="planet">&#x1F319;</div> {/* Moon Emoji */}
                    <h1>404</h1>
                    <p>Seite nicht gefunden</p>
                    <div className="planet">&#x1F319;</div> {/* Moon Emoji */}
                    <p>Sie scheinen im digitalen Weltraum verloren zu sein.</p>
                    <a href="/" className='not_found_link'>Zurück zur Startseite</a>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
