// src/pages/Google/Login.js

import React, { useEffect } from 'react';

// Function to fetch Google provider configuration
const getGoogleProviderCfg = async () => {
  const discoveryUrl = process.env.REACT_APP_GOOGLE_DISCOVERY_URL;
  console.log('Fetching Google provider configuration from:', discoveryUrl);
  try {
    const response = await fetch(discoveryUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching Google provider configuration:', error);
    return null; // Return null if an error occurs
  }
};

// GoogleLogin component
const GoogleLogin = () => {
  useEffect(() => {
    const loginWithGoogle = async () => {
      try {
        const googleProviderCfg = await getGoogleProviderCfg();
        if (!googleProviderCfg) throw new Error('Google provider configuration is unavailable');

        const authorizationEndpoint = googleProviderCfg.authorization_endpoint;
        console.log("Authorization endpoint:", authorizationEndpoint);

        const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const scopes = ['openid', 'email', 'profile'].join(' '); // Combine scopes into a string

        // Construct the request URI for Google login
        const requestUri = `${authorizationEndpoint}?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&response_type=code&access_type=offline`;
        console.log("Request URI for Google login:", requestUri);

        // Redirect to Google login page
        window.location.href = requestUri;
      } catch (error) {
        console.error("Error during Google login process:", error);
      }
    };

    loginWithGoogle();
  }, []);

  return (
    <div>
      <h1>Redirecting to Google...</h1>
    </div>
  );
};

export default GoogleLogin;
