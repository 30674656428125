import React, { useState } from 'react';
import "./register.css";

const Register = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <h1>Register</h1>
        <form action="/register" method="post" encType="multipart/form-data">
          <div className="form-group profile-container">
            <div className="profile-picture-container">
              <label htmlFor="profile_picture" className={`file-upload-label ${selectedImage ? 'has-image' : ''}`}>
                {selectedImage ? (
                  <img src={selectedImage} alt="Uploaded" className="profile-image" />
                ) : (
                  <span className="upload-icon">📷</span>
                )}
                <input
                  type="file"
                  id="profile_picture"
                  name="profile_picture"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
            <div className="form-fields">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="nachname">Nachname</label>
                <input type="text" className="form-control" id="nachname" name="nachname" required />
              </div>
              <div className="form-group">
                <label htmlFor="geburtstag">Geburtstag</label>
                <input type="date" className="form-control" id="geburtstag" name="geburtstag" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" name="password" required />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;