import React, { useState, useEffect } from 'react';
import './home.css';

const Home = () => {
  // State für Dropdown-Menü
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // State für den aktuellen Benutzer
  const [currentUser, setCurrentUser] = useState({
    isAuthenticated: false,
    name: '',
    profilePic: '',
    serveruser: false,
  });

  // Simulierte Authentifizierung (Ersetzen mit echter Logik, z. B. Cookies lesen)
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});

    if (cookies.name && cookies.picture) {
      setCurrentUser({
        isAuthenticated: true,
        name: cookies.name,
        profilePic: cookies.picture,
        serveruser: cookies.serveruser === 'true', // Falls du Serverrechte speicherst
      });
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="home-container">
      {/* Navigation */}
      <nav className="navbar">
        <div className="brand">
          <a href="#top" className="brand-text">Chronus Lock</a>
        </div>
        <div className="menu" id="menu">
          <ul className="menu-list">
            {currentUser.isAuthenticated ? (
              <div className="user-profile-container">
                <div className="user-button" onClick={toggleDropdown}>
                  <img
                    src={currentUser.profilePic || "https://via.placeholder.com/150"}
                    alt={currentUser.name}
                    className="profile-pic"
                  />
                  <div className="DropdownUser">{currentUser.name}</div>
                </div>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <a href="/profile">Profil</a>
                    {currentUser.serveruser && <a href="/server">Server</a>}
                    {currentUser.serveruser && <a href="/api/serversget">Server GET</a>}
                    <a href="/logout">Abmelden</a>
                  </div>
                )}
              </div>
            ) : (
              <li className="menu-item">
                <a href="/login" className="login-button">Anmelden</a>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {/* Holographic Text */}
      <div className="holographic-text">
        Willkommen bei Chronus Lock
      </div>

      {/* Footer */}
      <footer>
        <p>
          Alle Daten werden während der Entwicklungsphase gespeichert. Nutzung
          auf eigene Gefahr.
        </p>
      </footer>
    </div>
  );
};

export default Home;
